
.cell-name {

  .cell-name-container {
    display: flex;
    align-items: center;

    &.cell-name-container-single-use {
      font-style: italic;
    }
  }
  .code-container {
    font-weight: 700;
    color: var(--ion-color-medium);
    display: flex;
    align-items: center;
    min-width: 100px;
    margin-right: 2px;

    ion-icon {
      font-size: 20px;
      margin-right: 5px;
    }
  }
}

.cell-center {
  display: flex !important;
  align-items: center;
}

.cell-check {
  text-align: center;

  ion-icon {
    font-size: 20px;
  }
}

.dnd-placeholder,
.dnd-preview {
  background-color: white;
  border: 1px solid var(--ion-color-light-shade);
}

.cell-dnd {
  padding: 0 !important;

  .datatable-body-cell-label {
    height: 100%;
    overflow: hidden;

    & > div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-items: flex-start;
      justify-content: center;
      align-items: center;
      cursor: grab;

      ion-icon {
        font-weight: bold;
        font-size: 24px;
      }
    }
  }
}

.cell-checkbox {
  padding: 0 !important;
  display: flex !important;;
  align-items: center;
  justify-content: center;

  .datatable-body-cell-label,
  .datatable-header-cell-template-wrap {
    height: 18px !important;
    overflow: hidden;
  }

  .checkbox-masker {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    cursor: pointer;
  }
}

.cell-space-around {
  display: flex !important;
  justify-content: space-around;
}


.search-result-table {
  .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
    padding: 2px 5px !important;
  }

  datatable-body-cell .datatable-checkbox input[type=checkbox] {
    margin: 0 1rem;
  }
}

.illustration-cell {
  padding: 8px !important;
}

.datatable-header-cell {
  border-right: 1px solid var(--ion-color-light);
}

.header-cell-with-filter {
  margin-top: 0;
  margin-bottom: 0;

  .datatable-header-cell-template-wrap {

    ion-button {
      position: absolute;
      right: 5px;
      top: 5px;
      --padding-end: 4px;
      --padding-start: 4px;
    }
  }

  &.header-cell-small-padding {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
}

.header-cell-with-text-filter {

  .datatable-header-cell-template-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    height: 100%;
    margin: 0;
  }
}


.ngx-datatable.bootstrap:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: unset !important;
}


.selected-row {
  background-color: var(--ion-color-primary) !important;

  .datatable-body-cell {
    color: var(--ion-color-light) !important;
  }

  .link {
    color: var(--ion-color-light-tint) !important;

    &:hover {
      color: var(--ion-color-light) !important;
    }
  }

  .cell-name {
    .code-container {
      color: var(--ion-color-light) !important;
    }
  }
}

.ngx-datatable .datatable-footer .datatable-pager {
  flex: 1 1 50% !important;
}

.row-membership {
  background-color: #0d9dca47;
}
