@use "sass:meta";
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import '~@ali-hm/angular-tree-component/css/angular-tree-component.css';

@include meta.load-css('style/basic/animation');

.main-router-outlet {
  overflow: auto;

  & > * {
    min-width: 1160px;
  }
}

ion-icon {
  pointer-events: none;
}


.ng2-pdf-viewer-container {
  overflow-x: hidden !important;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 11px;
}

::-webkit-scrollbar:horizontal {
  height: 11px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, .5);
}

::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}

.overflow-scroll {
  overflow-y: auto !important;
}
